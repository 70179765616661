import { createApp } from "vue/dist/vue.esm-bundler";
import VueSmoothScroll from "vue3-smooth-scroll";

const App = {};

createApp(App)
  .use(VueSmoothScroll, {
    duration: 400,
    updateHistory: false,
  })
  .mount("#vue-app");
